import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Silent, Union } from "@/__main__/data-model.mjs";

const champion = {
  board_position: Number,
  board: Boolean,
  headliner_bonus_trait_array: [String],
  id: String,
  is_headliner: Boolean,
  items: [String],
  star: Number,
} satisfies ModelDefinition;

const player = {
  allies: [champion],
  champions: [champion],
  exp_cur: Number,
  exp_max: Number,
  game_name: String,
  gold_current: Number,
  gold_interest: Number,
  gold_passive: Number,
  gold_streak: Number,
  health: Number,
  items: [String],
  level: Number,
  offered_augments: [String],
  opponent: Union([String, null]),
  rerolls: Number,
  tag_line: String,
  win_streak: Number,
} satisfies ModelDefinition;

const roundData = {
  players: [player],
} satisfies ModelDefinition;

const model = {
  game_id: String,
  round_data: Mapped({
    key: String,
    value: Mapped({ key: String, value: roundData }),
  }),

  // Additional fields if needed
  _isLocal: Silent(Boolean),
} satisfies ModelDefinition;

const TFTMatchModel = createModel(model);

export type TFTMatch = FromModel<typeof TFTMatchModel>;

export default TFTMatchModel;
